'use client';

import React, { useEffect, useRef, useState } from 'react';
import { Block } from '../../components/Block';
import { Gutter } from '../../components/Gutter';
import { TypographyH3 } from '@/components/ui/h3';
import { TypographyP } from '@/components/ui/p';
import Lottie, { useLottie, useLottieInteractivity } from 'lottie-react';

import patientSatisfactionAnimation from '../../animations/patient-satisfaction.json';
import simplifyAnimation from '../../animations/simplify.json';
import techSolutions from '../../animations/tech-solutions.json';

import { Button } from '@/components/ui/button';
import { ChevronRight } from 'lucide-react';
import { motion, useScroll } from 'framer-motion';
import { TypographyH2 } from '@/components/ui/h2';

const Details = () => {
	const simplifyLottieContainer = useRef<HTMLDivElement>(null);
	const techSolutionsLottieContainer = useRef<HTMLDivElement>(null);
	const patientSatisfactionLottieContainer = useRef<HTMLDivElement>(null);

	const simplifyLottieObj = useLottie(
		{
			animationData: simplifyAnimation,
		},
		{
			height: 300,
			width: 300,
			scale: 1.5,
			position: 'relative',
			overflow: 'hidden',
		}
	);

	const techSolutionsLottieObj = useLottie(
		{
			animationData: techSolutions,
		},
		{
			height: 300,
			width: 300,
			scale: 1.5,
			position: 'relative',
			overflow: 'hidden',
		}
	);

	const patientSatisfactionLottieObj = useLottie(
		{
			animationData: patientSatisfactionAnimation,
		},
		{
			height: 300,
			width: 300,
			position: 'relative',
			overflow: 'hidden',
		}
	);

	const SimplifyAnimation = useLottieInteractivity({
		lottieObj: {
			...simplifyLottieObj,
			animationContainerRef: simplifyLottieContainer,
		},
		mode: 'scroll',
		actions: [
			{
				visibility: [0, 1],
				type: 'seek',
				frames: [0, 90],
			},
		],
	});

	const TechSolutionsAnimation = useLottieInteractivity({
		lottieObj: {
			...techSolutionsLottieObj,
			animationContainerRef: techSolutionsLottieContainer,
		},
		mode: 'scroll',
		actions: [
			{
				visibility: [0, 1],
				type: 'seek',
				frames: [0, 68],
			},
		],
	});

	const PatientSatisfactionAnimation = useLottieInteractivity({
		lottieObj: {
			...patientSatisfactionLottieObj,
			animationContainerRef: patientSatisfactionLottieContainer,
		},
		mode: 'scroll',
		actions: [
			{
				visibility: [0, 1],
				type: 'seek',
				frames: [0, 601],
			},
		],
	});

	return (
		<Block id='details' padding='medium'>
			<div className='w-full flex flex-col items-center overflow-hidden'>
				<Gutter padding='none'>
					<div className='flex flex-col items-center overflow-hidden '>
						<motion.div
							initial={{ opacity: 0, y: 30 }}
							whileInView={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
							viewport={{ once: true, amount: 'some' }}
							className='flex flex-col justify-between items-center py-6 max-w-xl '>
							<div className='w-full text-center'>
								<TypographyH2>Reduce operational costs</TypographyH2>
								<TypographyH3>
									Simplify and reduce your operational overhead
								</TypographyH3>
							</div>
							<div
								ref={simplifyLottieContainer}
								className='flex flex-col  justify-center items-center w-full h-fit'>
								{SimplifyAnimation}
							</div>
						</motion.div>

						<motion.div
							initial={{ opacity: 0, y: 30 }}
							whileInView={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
							viewport={{ once: true, amount: 'some' }}
							className='flex flex-col justify-between items-center py-6 max-w-xl '>
							<div
								ref={techSolutionsLottieContainer}
								className='flex flex-col order-2 relative justify-center items-center w-full h-fit'>
								{TechSolutionsAnimation}
							</div>

							<div className='w-full text-center'>
								<TypographyH2>Deliver healthcare excellence</TypographyH2>
								<TypographyH3>
									Leverage powerful technical solutions
								</TypographyH3>
							</div>
						</motion.div>

						<motion.div
							initial={{ opacity: 0, y: 30 }}
							whileInView={{ opacity: 1, y: 0, transition: { duration: 0.5 } }}
							viewport={{
								once: true,
								amount: 'some',
							}}
							className='flex flex-col justify-between items-center py-6 max-w-xl '>
							<div className='w-full text-center'>
								<TypographyH2>Enhance patient satisfaction</TypographyH2>
								<TypographyH3>Greatly improve patient experience</TypographyH3>
							</div>
							<div
								ref={patientSatisfactionLottieContainer}
								className='flex flex-col justify-center relative overflow-x-hidden  items-center w-full h-fit'>
								{PatientSatisfactionAnimation}
							</div>
						</motion.div>
					</div>
				</Gutter>
			</div>
		</Block>
	);
};

export default Details;
