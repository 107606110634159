import { cn } from '@/lib/utils';

export function TypographyH1({
	children,
	className,
}: {
	children: React.ReactNode;
	className?: string;
}) {
	return (
		<h1
			className={cn(
				'scroll-m-20 capitalize text-4xl font-extrabold font-title tracking-tight leading-normal lg:text-6xl',
				className
			)}>
			{children}
		</h1>
	);
}
